import React from "react";

const colors = new Map([
    ["danger", "#DD473B"],
    ["primary", "#006CB5"],
    ["warning", "#FFB946"],
    ["default", "#ADADAD"],
    ["black", "#000000"],
    ["white", "#FFFFFF"],
    ["success", "#77787B"]
]);

type ColorTypes = "danger" | "primary" | "warning" | "default" | "black" | "white" | "success"

interface IProps {
    color?: ColorTypes,
    size?: number
}

export const IconCloseX: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 4.5L4.5 13.5" stroke={colors.get(color)} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.5 4.5L13.5 13.5" stroke={colors.get(color)} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export const IconArrowLeft: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5242 14.1867L12.5242 9.25458L12.5242 4.81333C12.5242 4.05333 11.6058 3.67333 11.0675 4.21167L6.96667 8.3125C6.30959 8.96958 6.30959 10.0383 6.96667 10.6954L8.52625 12.255L11.0675 14.7962C11.6058 15.3267 12.5242 14.9467 12.5242 14.1867Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconArrowRight: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.47583 4.81333L6.47583 9.74542L6.47583 14.1867C6.47583 14.9467 7.39416 15.3267 7.9325 14.7883L12.0333 10.6875C12.6904 10.0304 12.6904 8.96167 12.0333 8.30458L10.4737 6.745L7.9325 4.20375C7.39416 3.67333 6.47583 4.05334 6.47583 4.81333Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconDanger: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z" fill={colors.get(color)} />
            <path d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z" fill={colors.get(color)} />
            <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconSuccess: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={colors.get(color)} fillRule="evenodd" d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z M15.2928932,8.29289322 L10,13.5857864 L8.70710678,12.2928932 C8.31658249,11.9023689 7.68341751,11.9023689 7.29289322,12.2928932 C6.90236893,12.6834175 6.90236893,13.3165825 7.29289322,13.7071068 L9.29289322,15.7071068 C9.68341751,16.0976311 10.3165825,16.0976311 10.7071068,15.7071068 L16.7071068,9.70710678 C17.0976311,9.31658249 17.0976311,8.68341751 16.7071068,8.29289322 C16.3165825,7.90236893 15.6834175,7.90236893 15.2928932,8.29289322 Z" />
        </svg>
    );
};

export const IconLogo: React.FC<IProps> = ({ color = "default", size = 480 }) => {
    return (
        <svg width={size} height="70" viewBox="0 0 480 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={"#fff"} d="M34,61.21V63.9h5.23v2.67H31.4V61.21a27.09,27.09,0,0,1-16.18-6.36L17.08,53a24.51,24.51,0,0,0,31.25,0l1.86,1.89A27.06,27.06,0,0,1,34,61.21Zm18-12A25.59,25.59,0,0,0,50.28,15.4a24.58,24.58,0,0,0-15-7.27V5.45a27.17,27.17,0,0,1,16.81,8.06,28.33,28.33,0,0,1,1.76,37.58L52,49.19ZM30.09,8.13a24.5,24.5,0,0,0-13,5.48l-1.86-1.89A27.13,27.13,0,0,1,30.09,5.45V8.13ZM11.52,51.08A28,28,0,0,1,5.36,36H8a25.43,25.43,0,0,0,5.39,13.24l-1.86,1.89ZM5.36,30.62a28,28,0,0,1,6.16-15.13l1.86,1.89A25.36,25.36,0,0,0,8,30.62Z" />
            <path fill={"#fff"} d="M48.41,47.37l8.35,8.51-1.85,1.88q-4.17-4.26-8.35-8.5a20.68,20.68,0,0,1-26.69.85l1.87-1.9a18.08,18.08,0,0,0,23.92-1.75,18.88,18.88,0,0,0,0-26.36,18.08,18.08,0,0,0-23.92-1.75l-1.87-1.89A20.61,20.61,0,0,1,31.4,12V0H34V12a20.73,20.73,0,0,1,13.49,6.2,21.58,21.58,0,0,1,.9,29.15Zm-32.24-1A21.39,21.39,0,0,1,11.94,36h2.64A18.66,18.66,0,0,0,18,44.45l-1.87,1.9ZM11.94,30.62a21.35,21.35,0,0,1,4.23-10.4L18,22.12a18.62,18.62,0,0,0-3.46,8.5Z" />
            <path fill={"#fff"} d="M32.71,18.64a14.21,14.21,0,0,1,10.17,4.29,14.81,14.81,0,0,1,0,20.71,14.2,14.2,0,0,1-19.38.9l-13,13.22L8.66,55.88l13-13.23a14.77,14.77,0,0,1-3.28-8H0V32H18.37a14.8,14.8,0,0,1,3.28-8l-13-13.22,1.85-1.88L23.5,22a14.16,14.16,0,0,1,9.21-3.39ZM41,24.81a11.63,11.63,0,0,0-16.65,0,12.13,12.13,0,0,0,0,17,11.65,11.65,0,0,0,16.65,0,12.13,12.13,0,0,0,0-16.95Z" />
            <text fill={"#fff"} style={{ fontSize: "30px" }} xmlns="http://www.w3.org/2000/svg" transform="translate(78.35 46.67)">Университет Решетнёва</text>
        </svg >
    );
};

export const IconUser: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7471 25.5862C28.6479 25.5862 31.4299 24.4338 33.4811 22.3827C35.5323 20.3315 36.6846 17.5495 36.6846 14.6487C36.6846 11.7479 35.5323 8.96588 33.4811 6.9147C31.4299 4.86352 28.6479 3.71118 25.7471 3.71118C22.8463 3.71118 20.0643 4.86352 18.0132 6.9147C15.962 8.96588 14.8096 11.7479 14.8096 14.6487C14.8096 17.5495 15.962 20.3315 18.0132 22.3827C20.0643 24.4338 22.8463 25.5862 25.7471 25.5862ZM12.4659 28.7112C11.0155 28.7112 9.62448 29.2874 8.59889 30.3129C7.5733 31.3385 6.99713 32.7295 6.99713 34.1799V34.9612C6.99713 38.7002 9.37682 41.8627 12.7549 44.0127C16.1518 46.1752 20.7503 47.4612 25.7471 47.4612C30.744 47.4612 35.3409 46.1752 38.7393 44.0127C42.1174 41.8627 44.4971 38.7002 44.4971 34.9612V34.1799C44.4971 32.7295 43.921 31.3385 42.8954 30.3129C41.8698 29.2874 40.4788 28.7112 39.0284 28.7112H12.4659Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconLock: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 4.16663C19.2563 4.16663 14.5833 8.83954 14.5833 14.5833V20.8333H12.5C11.3949 20.8333 10.3351 21.2723 9.55373 22.0537C8.77233 22.8351 8.33334 23.8949 8.33334 25V41.6666C8.33334 42.7717 8.77233 43.8315 9.55373 44.6129C10.3351 45.3943 11.3949 45.8333 12.5 45.8333H37.5C38.6051 45.8333 39.6649 45.3943 40.4463 44.6129C41.2277 43.8315 41.6667 42.7717 41.6667 41.6666V25C41.6667 23.8949 41.2277 22.8351 40.4463 22.0537C39.6649 21.2723 38.6051 20.8333 37.5 20.8333H35.4167V14.5833C35.4167 8.83954 30.7438 4.16663 25 4.16663ZM18.75 14.5833C18.75 11.1375 21.5542 8.33329 25 8.33329C28.4458 8.33329 31.25 11.1375 31.25 14.5833V20.8333H18.75V14.5833ZM27.0833 36.9229V41.6666H22.9167V36.9229C22.1883 36.5058 21.6035 35.8777 21.2392 35.1215C20.875 34.3653 20.7485 33.5165 20.8764 32.687C21.0043 31.8575 21.3807 31.0862 21.9558 30.4748C22.5309 29.8635 23.2777 29.4408 24.0979 29.2625C24.7071 29.1278 25.3388 29.1315 25.9464 29.2735C26.5539 29.4154 27.1219 29.6919 27.6083 30.0826C28.0948 30.4733 28.4873 30.9682 28.757 31.5308C29.0267 32.0934 29.1667 32.7094 29.1667 33.3333C29.1655 34.0619 28.9723 34.7774 28.6065 35.4076C28.2408 36.0377 27.7154 36.5604 27.0833 36.9229Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconArrowDown: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1867 6.47583L9.25463 6.47583L4.81338 6.47583C4.05338 6.47583 3.67338 7.39417 4.21171 7.9325L8.31254 12.0333C8.96963 12.6904 10.0384 12.6904 10.6955 12.0333L12.255 10.4737L14.7963 7.9325C15.3267 7.39417 14.9467 6.47583 14.1867 6.47583Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconArrowUp: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.54706 9.22833L7.18122 9.22833L10.4537 9.22833C11.0137 9.22833 11.2937 8.55167 10.8971 8.155L7.87539 5.13333C7.39122 4.64917 6.60372 4.64917 6.11956 5.13333L4.97039 6.2825L3.09789 8.155C2.70706 8.55167 2.98706 9.22833 3.54706 9.22833Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconAVk: React.FC<IProps> = ({ size = 24 }) => {
    return (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Слой_1" x="0px" y="0px" viewBox="0 0 24 24" xmlSpace="preserve">
            <title id="simpleicons-vk-icon">VK icon</title>
            <rect y="0" width="24" height="24" fill="#FFFFFF" />
            <path fill="#51DD00" d="M6.9,5.4h3.5l2.2,12.2h-2.6L9.5,15H7.9l-0.4,2.6H4.8L6.9,5.4z M8.7,7.4L8,13.3h1.2L8.7,7.4z" />
            <path fill="#0068FF" d="M14.8,17.5h0.6c0,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.3,0.1-0.3s0-0.9,0.4-1c0.4-0.1,0.9,0.9,1.4,1.2  c0.4,0.3,0.7,0.2,0.7,0.2h1.4c0,0,0.8-0.1,0.4-0.6c-0.1-0.1-0.2-0.4-1.1-1.3c-0.9-0.9-0.8-0.7,0.3-2.1c0.7-0.9,0.9-1.4,0.9-1.6  c-0.1-0.2-0.6-0.2-0.6-0.2h-1.6c0,0-0.1,0-0.2,0.1c-0.1,0.1-0.1,0.2-0.1,0.2s-0.2,0.7-0.6,1.3c-0.7,1.2-1,1.3-1.1,1.2  c-0.3-0.2-0.2-0.7-0.2-1.1c0-1.1,0.2-1.7-0.4-1.8c-0.2-0.1-0.3-0.1-0.8-0.1c-0.6,0-1.1,0-1.4,0.1c-0.1,0.1-0.3,0.3-0.2,0.3  c0.1,0,0.4,0.1,0.5,0.2c0.2,0.2,0.2,0.7,0.2,0.7s0.1,1.4-0.2,1.6c-0.2,0.1-0.5-0.1-1.1-1.3c-0.4-0.5-0.6-1.1-0.6-1.1  s-0.1-0.2-0.1-0.2c-0.2-0.1-0.3-0.1-0.3-0.1H9.9c0,0-0.2,0-0.3,0.1c-0.1,0.1,0,0.3,0,0.3s1.2,2.8,2.6,4.2  C13.4,17.7,14.8,17.5,14.8,17.5C14.8,17.5,14.8,17.5,14.8,17.5z" />
        </svg>
    )
}

export const IconATelegramm: React.FC<IProps> = ({ size = 24 }) => {
    return (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Слой_1" x="0px" y="0px" viewBox="0 0 24 24" xmlSpace="preserve">
            <title id="simpleicons-vk-icon">Telegramm icon</title>
            <rect y="0" width="24" height="24" fill="#FFFFFF" />
            <path fill="#51DD00" d="M7.3,5.4h3.5l2.2,12.2h-2.6L9.9,15H8.4L8,17.6H5.2L7.3,5.4z M9.1,7.4l-0.7,5.9h1.2L9.1,7.4z" />
            <path fill="#0068FF" d="M18.2,10.4l-8.1,3.2c-0.3,0.1-0.2,0.5,0,0.6l2.1,0.6l0.8,2.4c0.1,0.3,0.4,0.3,0.6,0.1l1.1-1.1l2.1,1.5  c0.3,0.2,0.6,0,0.7-0.3l1.4-6.6C18.9,10.6,18.5,10.3,18.2,10.4z M17.2,11.9l-3.8,3.3c0,0-0.1,0.1-0.1,0.1l-0.1,1.3c0,0-0.1,0-0.1,0  l-0.6-1.9c0-0.1,0-0.2,0.1-0.2l4.5-2.8C17.2,11.7,17.3,11.8,17.2,11.9z" />
        </svg>
    )
}

export const IconTelegramm: React.FC<IProps> = ({ size = 24 }) => {
    return (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 455.731 455.731" xmlSpace="preserve">
            <g>
                <rect x="0" y="0" fill="#61A8DE" width="455.731" height="455.731" />
                <path fill="#FFFFFF" d="M358.844,100.6L54.091,219.359c-9.871,3.847-9.273,18.012,0.888,21.012l77.441,22.868l28.901,91.706   c3.019,9.579,15.158,12.483,22.185,5.308l40.039-40.882l78.56,57.665c9.614,7.057,23.306,1.814,25.747-9.859l52.031-248.76   C382.431,106.232,370.443,96.08,358.844,100.6z M320.636,155.806L179.08,280.984c-1.411,1.248-2.309,2.975-2.519,4.847   l-5.45,48.448c-0.178,1.58-2.389,1.789-2.861,0.271l-22.423-72.253c-1.027-3.308,0.312-6.892,3.255-8.717l167.163-103.676   C320.089,147.518,324.025,152.81,320.636,155.806z" />
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}

export const IconVk: React.FC<IProps> = ({ size = 24 }) => {
    return (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Слой_1" x="0px" y="0px" viewBox="0 0 24 24" xmlSpace="preserve">
            <title id="simpleicons-vk-icon">VK icon</title>
            <rect y="0" fill="#4D7198" width="24" height="24" />
            <path fill="#FFFFFF" d="M11.7,17.5h1.1c0,0,0.3,0,0.6-0.2c0.2-0.2,0.2-0.5,0.2-0.5s0-1.6,0.7-1.8s1.6,1.6,2.6,2.2  c0.7,0.5,1.3,0.4,1.3,0.4h2.6c0,0,1.4-0.1,0.7-1.1c-0.1-0.1-0.4-0.8-2-2.3c-1.6-1.6-1.4-1.3,0.6-3.9c1.2-1.6,1.7-2.6,1.6-3  C21.5,6.9,20.6,7,20.6,7h-2.9c0,0-0.2,0-0.4,0.1c-0.2,0.1-0.2,0.3-0.2,0.3S16.7,8.6,16,9.7c-1.3,2.2-1.9,2.4-2,2.2  c-0.5-0.3-0.3-1.3-0.3-2c0-2.1,0.3-3.1-0.7-3.3c-0.3-0.1-0.6-0.2-1.4-0.2c-1.1,0-2,0-2.5,0.2C8.9,6.8,8.5,7.1,8.7,7.2  c0.2,0,0.7,0.1,0.9,0.4C9.9,8,9.9,8.9,9.9,8.9s0.2,2.5-0.4,2.9c-0.4,0.2-1-0.2-2.1-2.3c-0.7-1-1.1-2.1-1.1-2.1S6.2,7.1,6.1,7  C5.8,6.9,5.6,6.9,5.6,6.9H2.8c0,0-0.4,0-0.6,0.2s0,0.5,0,0.5s2.2,5.2,4.7,7.8C9.2,17.7,11.8,17.5,11.7,17.5  C11.8,17.5,11.7,17.5,11.7,17.5z" />
        </svg>
    )
}