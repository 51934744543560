import React from 'react'
import "./Loader.scss";

const Loader = () => {
    return (
        <div className="loader-container">
            <div className='loader-item'></div>
            <div className='loader-item'></div>
            <div className='loader-item'></div>
            <div className='loader-item'></div>
            <div className='loader-item'></div>
            <div className='loader-item'></div>
            <div className='loader-item'></div>
            <div className='loader-item'></div>
            <div className='loader-item'></div>
            <div className='loader-item'></div>
            <div className='loader-item'></div>
            <div className='loader-item'></div>
        </div>
    )
}

export default Loader